import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
// import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Gvar from '../utils/Gvar';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import ContainerLayout from '@material-ui/core/Container';
import Utils from '../utils/Utils';
import MemberLayoutNoBottomTab from '../layouts/MemberLayoutNoBottomTab';
import { Box } from '@material-ui/core';

// const useStyles = makeStyles(theme => ({
//     root: {
//         flexGrow: 1,
//     },
//     menuButton: {
//         marginRight: theme.spacing(2),
//     },
//     title: {
//         flexGrow: 1,
//     },
//     }));

class ProdukGroup extends Component {

    constructor(props){
        super(props);
        this.state = ({
            list_group :[],
            title : 'Pilih Produk'
        });
    }

    componentDidMount(){
        // this.props.appContext.setState({showLoading:false})
        this.getProdukGroup();
    }

    getProdukGroup(){
        // Gvar.appContext.setState({ showSnackBar: false, textSnackBar :""});
        var currentURL = window.location.href;
        var jenis = currentURL.split("jenis=")[1];
        var bodyJson = { 
            jenis: jenis,
            id_member:Utils.getDataMember().id,
            token: Utils.getMyToken()
        }
        fetch(Gvar.server+'index.php/produk/group', {
            method: 'post',
            body: JSON.stringify(bodyJson),
        }).then(res=>res.json()
        ).then(res => {
            // console.log(res)
            this.setState({list_group:res.data}); 
        }).catch(err => {
            // Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            // Gvar.appContext.setState({showLoading:false})
        })
    }
    
    onClickItem(group,jenis){
        if(jenis==='PEMBAYARAN'){
            this.props.history.push('/produkpembayaran?group='+group+'&jenis='+jenis);
        }else{
            this.props.history.push('/produknonprefix?group='+group+'&jenis='+jenis);    
        }
    }

    render() {
        return (
            <MemberLayoutNoBottomTab title={this.state.title} history={this.props.history}>
                <Grid container spacing={3} style={{marginTop:16}}>
                    {this.state.list_group.map((item,index) => (
                    <Grid onClick={()=>{
                        this.onClickItem(item.group_produk,item.jenis)
                    }} key={index} item xs={6} sm={4}>
                        <Paper>
                            <img src={Gvar.pathImage+item.icon} alt={''} style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                        </Paper>
                        <Box textAlign={"center"} marginTop={"8px"}>
                            {item.group_produk.replace(/_/g,' ')}
                        </Box>
                    </Grid>
                    ))}
                </Grid>
            </MemberLayoutNoBottomTab>
        );
    }
}

export default (ProdukGroup);
