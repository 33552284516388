import React, { Component } from 'react';
import Grow from '@material-ui/core/Grow';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from '../../../utils/Gvar';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { Cancel, RemoveShoppingCart } from '@material-ui/icons';
import Utils from '../../../utils/Utils';
import Logger from '../../../utils/Logger';

class Cart extends Component {

    constructor(props){
        super(props);
        this.state = ({
            jenisKartu : '',
            showSnackBar : true,
            textSnackBar : '',
            carts : [],
            showConfirmDialog : false,
            showConfirmPIN : false,
            itemSelected : null
        });
    }

    componentDidMount(){
        this.getDraftTrx()
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }

    getDraftTrx(){
        this.setState({showLoading:true,showSnackBar: false})
        var bodyJson = {
            start_date : this.formatDate(new Date()),
            end_date : this.formatDate(new Date()),
            no_tujuan : '',
            id_member : Gvar.dataMember.id,
            token : Utils.getMyToken(),
            status : "DRAF",
            reset_draf : localStorage.getItem("reset_draf")
        }
        
        fetch(Gvar.server+'index.php/histori/transaksi', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json())
        .then(res => {
            this.setState({showLoading:false});
            if(res.status_code === '200'){
                this.setState({showLoading:false, carts:res.data});
                this.props.setBadgeCart(res.data.length);
            }else{
                this.setState({ showSnackBar: true, textSnackBar: res.message});
            }
           
        }).catch(err => {
            // Logger.d(err.toString());
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading:false});
        })
    }

    cancelDraft(){
        this.setState({showLoading:true,showSnackBar: false})
        var bodyJson = {
            id_member : Gvar.dataMember.id,
            token : Utils.getMyToken(),
            id_request : this.state.itemSelected.id_request
        }
        Logger.d(bodyJson);
        fetch(Gvar.server+'index.php/transaksi/batalkan_draf_transaksi', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json())
        .then(res => {
            if(res.status_code == "200"){
                this.setState({ showSnackBar: true, textSnackBar :res.message, showLoading:false});
                this.getDraftTrx();
            }else{
                this.setState({ showSnackBar: true, textSnackBar :res.message, showLoading:false});
            }
        }).catch(err => {
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading:false});
        })
    }

    render() {
        const polygon = (
            <>
                <Snackbar
                    style={{marginTop:54}}
                    anchorOrigin={{ vertical: 'bottom', horizontal :'right' }}
                    open={this.state.showSnackBar}
                    onClose={()=>this.setState({showSnackBar:false})}
                    message={this.state.textSnackBar}
                />
                <List>
                    {this.state.carts.map((data,index) => (
                        <>
                        <ListItem key={'cart-'+index} role={undefined} dense>
                            <ListItemText primary={data.no_tujuan + ' . ' + data.nama_produk} secondary={Utils.formatRupiah(data.total,'.')}/>
                            <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="remove" onClick={()=>this.setState({showConfirmDialog:true, itemSelected:data})}>
                                <Cancel />
                            </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="fullWidth" component={'li'}/>
                        </>
                    ))}
                </List>
                
                {this.state.carts.length == 0 && !this.state.showLoading?(
                    <>
                        <Box display={"flex"} justifyContent={"center"} marginTop={"100px"}>
                            <RemoveShoppingCart/>
                        </Box>
                        <Typography align='center' variant="h6" component="h2">
                        Keranjang masih kosong
                        </Typography>
                    </>
                ):null}
                
                <Dialog open={this.state.showConfirmDialog} scroll='paper' aria-labelledby="Detail">
                    <DialogTitle id="simple-dialog-title" >Konfirmasi</DialogTitle>
                    <DialogContent>
                        Apakah Anda yakin ingin menghapus item no tujuan "{this.state.itemSelected?.no_tujuan}" produk "{this.state.itemSelected?.nama_produk}"?
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" style={{float:'right',margin:5}}  onClick={()=>{
                            this.setState({showConfirmDialog : false});
                        }}>Batal</Button>
                        <Button color="primary" onClick={()=>{
                            this.setState({showConfirmDialog : false});
                            this.cancelDraft()
                        }}>Ya</Button>
                    </DialogActions>
                </Dialog>
            </>
          );
                    
        return (
            <>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                {polygon}
                </Grow>
            </>
        );
    }
}


export default Cart;
