import Gvar from './Gvar';

export default class Logger{
    static currentDate(){
        var date = new Date();
        var dateStr =
        ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
        ("00" + date.getDate()).slice(-2) + "/" +
        date.getFullYear() + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);

        return dateStr;
    }
    static t(title, description=null){
        if(!Gvar.isProduction){
            if(description == null){
                console.log('['+this.currentDate()+']'+'[TRACE] ', title);
            }else{
                console.log('['+this.currentDate()+']'+'[TRACE] '+title, description);
            }
        }
    }

    static d(title, description=null){
        if(!Gvar.isProduction){
            if(description == null){
                console.log('['+this.currentDate()+']'+'[DEBUG] ', title);
            }else{
                console.log('['+this.currentDate()+']'+'[DEBUG] '+title, description);
            }
        }
    }

    static e(title, description=null){
        if(!Gvar.isProduction){
            if(description == null){
                console.log('['+this.currentDate()+']'+'[ERROR] ', title);
            }else{
                console.log('['+this.currentDate()+']'+'[ERROR] '+title, description);
            }
        }
    }
}


