import React, { createContext, useEffect, useState } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(()=>{
        if(localStorage.getItem('data_member')){
            setUser(JSON.parse(localStorage.getItem('data_member')))
        }else{
            localStorage.setItem('data_member', "{}")
        }
        
    },[])

    const login = (res) => {
        console.log(res)
        localStorage.setItem('is_logged_in', true);
        localStorage.setItem('token', res.token);   
        localStorage.removeItem('bindingAcc');
        localStorage.setItem('data_member', JSON.stringify(res.data_member));
        setUser(res.data_member);
    };

    const logout = () => {
        localStorage.removeItem('data_member');
        setUser(null);
    };

    return (
        <SessionContext.Provider value={{ user, login, logout }}>
        {children}
        </SessionContext.Provider>
    );
};

// Hook untuk menggunakan Context
export default SessionContext;